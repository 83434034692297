import * as React from "react";
import { FileOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { Checkbox, Col, Row, Spin } from "antd";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { FormComponentProps } from "@ant-design/compatible/lib/form";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import ConfigurationStore from "stores/ConfigurationStore";

// ==== Types ====
import {
  EOnboardingScreens,
  EOnboardingForms,
  IDocumentsForm,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import {
  DOCUMENTS,
  IDocumentEnumType,
} from "views/SignUp/Onboarding/types/onboardingEnums";
import type { CheckboxProps } from "antd";

// ==== Components ====
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";

// ==== ApiHandlers ====
import { getMasterDocumentDownload } from "apiHandlers/ProfileApiHandler";

// ==== Utilities ====
import { capitalize } from "utilities/genericUtilities";

export const DOCUMENTS_LIST = [
  DOCUMENTS.DIRECT_CUSTOMER_ACCOUNT_AGREEMENT,
  DOCUMENTS.CUSTOMER_RELATIONSHIP_SUMMARY,
  DOCUMENTS.PRIVACY_POLICY,
  DOCUMENTS.REGULATION_BEST_INTEREST,
  DOCUMENTS.RISKS_ASSOCIATED_WITH_ELECTRONIC_TRADING,
  DOCUMENTS.MARKET_DATA_AGREEMENT,
];

const DocumentsForm: React.FunctionComponent<FormComponentProps> = observer(
  ({ form }) => {
    const { t } = useTranslation();
    const { getFieldDecorator } = form;

    const [nonPro, setNonPro] = React.useState(null);
    const [pro, setPro] = React.useState(null);

    const isNextButtonDisabled = (): {
      disabledMessage: string;
      isDisabled: boolean;
    } => {
      let isDisabled = false;
      let disabledMessage = "";

      // Only US Citizens need to check the W9 box
      if (
        OnboardingStore.isUserUSCitizen() &&
        !getInitialValue("isW9Checked")
      ) {
        isDisabled = true;
        disabledMessage = `${t("views.signUp.forms.DocumentsForm.errors.w9")}`;
      }

      // Only NON US Citizens need to check the W8 box
      if (
        !OnboardingStore.isUserUSCitizen() &&
        !getInitialValue("isW8Checked")
      ) {
        isDisabled = true;
        disabledMessage = `${t("views.signUp.forms.DocumentsForm.errors.w8")}`;
      }

      // Make sure pro or non pro is selected for market data agreement
      if (!pro && !nonPro) {
        isDisabled = true;
        disabledMessage = `${t(
          "views.signUp.forms.DocumentsForm.errors.proOrNonPro",
        )}`;
      }

      return { disabledMessage, isDisabled };
    };

    const goForwardHandler = () => {
      let proOrNonPro;
      if (nonPro === "1") {
        proOrNonPro = 1;
      } else if (nonPro === "0") {
        proOrNonPro = 0;
      } else {
        proOrNonPro = null;
      }

      OnboardingStore.validateDocumentsInfo(
        EOnboardingScreens.SUCCESS,
        proOrNonPro,
      );
    };

    const downloadDirectCustomerAccountAgreement = async () => {
      await getMasterDocumentDownload(
        DOCUMENTS.DIRECT_CUSTOMER_ACCOUNT_AGREEMENT.documentName,
      );
    };

    const downloadMarketAgreement = async () => {
      await getMasterDocumentDownload(
        DOCUMENTS.MARKET_DATA_AGREEMENT.documentName,
      );
    };

    const getInitialValue = (key: string) =>
      OnboardingStore.getFormItemData(EOnboardingForms.DOCUMENTS, key);

    const renderDocuments = () => {
      const handleFileDownload = async (
        document: IDocumentEnumType,
      ): Promise<void> => {
        await getMasterDocumentDownload(document.documentName);
      };

      return (
        <div className="bodySection">
          <p className="subheader">
            {t("views.signUp.forms.DocumentsForm.importDocuments")}
          </p>
          <React.Fragment>
            {DOCUMENTS_LIST.map((document: IDocumentEnumType) => {
              const downloadFile = () => handleFileDownload(document);

              return (
                <div className="documentWrapper" key={document.documentName}>
                  {OnboardingStore.isFileDownloading ===
                  document.documentName ? (
                    <Spin size="small" style={{ paddingRight: 10 }} />
                  ) : (
                    <FileOutlined className="documentIcon" />
                  )}
                  <span className="documentLink" onClick={downloadFile}>
                    {t(document.translationKey)}
                  </span>
                </div>
              );
            })}
          </React.Fragment>
        </div>
      );
    };

    const renderNOBO = () => (
      <div className="bodySection">
        <p className="subheader">
          {t("views.signUp.forms.DocumentsForm.nobo_header")}
        </p>
        <p>{t("views.signUp.forms.DocumentsForm.nobo_body")}</p>
        <Row style={{ marginTop: 25 }}>
          <Col>
            <Form.Item>
              {getFieldDecorator("isNoboChecked", {
                initialValue: getInitialValue("isNoboChecked"),
              })(
                <Checkbox checked={getInitialValue("isNoboChecked")}>
                  <strong>
                    {`${t(
                      "views.signUp.forms.DocumentsForm.nobo_question",
                    )} ${`(${capitalize(t("forms.fields.optional"))})`}`}
                  </strong>
                </Checkbox>,
              )}
            </Form.Item>
          </Col>
        </Row>
      </div>
    );

    const renderIRSBox = (legalese: string) => {
      return (
        <div className="irsBox">
          <strong>{legalese}</strong>
        </div>
      );
    };

    const handleMarketAgreementCheck1: CheckboxProps["onChange"] = (e) => {
      setNonPro("1");
      setPro(null);
    };

    const handleMarketAgreementCheck2: CheckboxProps["onChange"] = (e) => {
      setPro("0");
      setNonPro(null);
    };

    const renderMarketAgreementCheckboxes = () => {
      return (
        <Row>
          <Col span={24}>
            <Checkbox
              onChange={handleMarketAgreementCheck1}
              value={1}
              checked={nonPro}
            >
              {t("views.signUp.forms.DocumentsForm.marketAgreementCheck1")}
            </Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox
              onChange={handleMarketAgreementCheck2}
              value={0}
              checked={pro}
            >
              {t("views.signUp.forms.DocumentsForm.marketAgreementCheck2")}
            </Checkbox>
          </Col>
        </Row>
      );
    };

    const renderTaxForm = () => {
      // W-9 rendered for US citizens
      if (OnboardingStore.isUserUSCitizen()) {
        return (
          <div className="bodySection">
            <div className="header">
              {t("views.signUp.forms.DocumentsForm.w9Form.header")}
            </div>
            <p>
              <strong>
                {t("views.signUp.forms.DocumentsForm.underPenalty")}
              </strong>
            </p>
            <ul>
              <li>{t("views.signUp.forms.DocumentsForm.w9Form.line1")}</li>
              <li>
                {`${OnboardingStore.getFormItemData(
                  EOnboardingForms.PERSONAL_INFO,
                  "USTaxId",
                )} ${t("views.signUp.forms.DocumentsForm.w9Form.line2")}`}
              </li>
              <li>{t("views.signUp.forms.DocumentsForm.w9Form.line3")}</li>
              <li>{t("views.signUp.forms.DocumentsForm.w9Form.line4")}</li>
            </ul>
            <Row style={{ marginTop: 25 }}>
              <Col>
                <Form.Item>
                  {getFieldDecorator("isW9Checked", {
                    initialValue: getInitialValue("isW9Checked"),
                  })(
                    <Checkbox
                      checked={getInitialValue("isW9Checked")}
                      className="requiredAsteriskIcon"
                    >
                      <strong>
                        {t(
                          "views.signUp.forms.DocumentsForm.w9Form.w9_question",
                        )}
                      </strong>
                    </Checkbox>,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
        );
      } else {
        // W-8BEN rendered for US citizens
        return (
          <div className="bodySection">
            <div className="header">
              {t("views.signUp.forms.DocumentsForm.w8Form.header")}
            </div>
            <p>
              <strong>
                {t("views.signUp.forms.DocumentsForm.underPenalty")}
              </strong>
            </p>
            <ul>
              <li>{t("views.signUp.forms.DocumentsForm.w8Form.line1")}</li>
              <li>{t("views.signUp.forms.DocumentsForm.w8Form.line2")}</li>
              <li>
                {`${OnboardingStore.getFormItemData(
                  EOnboardingForms.PERSONAL_INFO,
                  "ForeignTaxId",
                )} ${t("views.signUp.forms.DocumentsForm.w8Form.line3")}`}
              </li>
              <li>
                {t("views.signUp.forms.DocumentsForm.w8Form.line4", {
                  country: ConfigurationStore.getCountryWithCountryCode(
                    OnboardingStore.getFormItemData(
                      EOnboardingForms.PERSONAL_INFO,
                      "CountryOfCitizenship",
                    ),
                  ),
                })}
              </li>
              <li>{t("views.signUp.forms.DocumentsForm.w8Form.line5")}</li>
              <li>
                {t("views.signUp.forms.DocumentsForm.w8Form.line6")}
                <strong>
                  {" "}
                  {t("views.signUp.forms.DocumentsForm.w8Form.line7")}
                </strong>
              </li>
            </ul>
            <Row style={{ marginTop: 25 }}>
              <Col>
                <Form.Item>
                  {getFieldDecorator("isW8Checked", {
                    initialValue: getInitialValue("isW8Checked"),
                  })(
                    <Checkbox
                      checked={getInitialValue("isW8Checked")}
                      className="requiredAsteriskIcon"
                    >
                      <strong>
                        {t(
                          "views.signUp.forms.DocumentsForm.w8Form.w8_question1",
                        )}
                      </strong>
                    </Checkbox>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <p>
              <strong>
                {t("views.signUp.forms.DocumentsForm.underPenalty")}
              </strong>
            </p>
            <ul>
              <li>{t("views.signUp.forms.DocumentsForm.w8Form.line8")}</li>
              <li>{t("views.signUp.forms.DocumentsForm.w8Form.line9")}</li>
              <li>{t("views.signUp.forms.DocumentsForm.w8Form.line10")}</li>
              <li>{t("views.signUp.forms.DocumentsForm.w8Form.line11")}</li>
            </ul>
            <Row style={{ marginTop: 25 }}>
              <Col>
                <Form.Item>
                  {getFieldDecorator("isW8ExemptChecked", {
                    initialValue: getInitialValue("isW8ExemptChecked"),
                  })(
                    <Checkbox checked={getInitialValue("isW8ExemptChecked")}>
                      <strong>
                        {`${t(
                          "views.signUp.forms.DocumentsForm.w8Form.w8_question2",
                        )} ${`(${capitalize(t("forms.fields.optional"))})`}`}
                      </strong>
                    </Checkbox>,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
        );
      }
    };

    const renderClientCertification = () => (
      <div className="bodySection">
        <div style={{ paddingBottom: 10 }}>
          <strong>
            {t("views.signUp.forms.DocumentsForm.acceptAndContinue")}
          </strong>
        </div>
        <ul>
          <li>{t("views.signUp.forms.DocumentsForm.bulletPoint1")}</li>
          <li>{t("views.signUp.forms.DocumentsForm.bulletPoint2")}</li>
          <li>
            {t("views.signUp.forms.DocumentsForm.bulletPoint3a")}
            <u
              className="documentLink"
              onClick={downloadDirectCustomerAccountAgreement}
            >
              {` "${t(
                DOCUMENTS.DIRECT_CUSTOMER_ACCOUNT_AGREEMENT.translationKey,
              )}" `}
            </u>
            {t("views.signUp.forms.DocumentsForm.bulletPoint3b")}
          </li>
          <li>{t("views.signUp.forms.DocumentsForm.bulletPoint4")}</li>
          <li>
            <strong>
              {t("views.signUp.forms.DocumentsForm.marketAcknowledge")}
              <u className="documentLink" onClick={downloadMarketAgreement}>
                {t("views.signUp.forms.DocumentsForm.marketAgreementLink")}
              </u>
              {t("views.signUp.forms.DocumentsForm.marketAcknowledgeEnd")}
            </strong>
            {renderMarketAgreementCheckboxes()}
          </li>
          <li>{t("views.signUp.forms.DocumentsForm.bulletPoint5")}</li>
        </ul>
        <Row>
          <Col span={12}>
            {renderIRSBox(t("views.signUp.forms.DocumentsForm.theIRSDoesNot"))}
          </Col>
          <Col span={12}>
            {renderIRSBox(t("views.signUp.forms.DocumentsForm.iUnderstand"))}
          </Col>
        </Row>
      </div>
    );

    const goBackHandler = (e: React.FormEvent<HTMLFormElement>) => {
      FormsStore.setCurrentScreen(EOnboardingScreens.SUMMARY);
    };

    return (
      <div className="slideInWrapper documentsForm">
        <FormHeader
          headerText={t("views.signUp.forms.DocumentsForm.header")}
          subheaderText={t("views.signUp.forms.DocumentsForm.subheader")}
        />
        {renderTaxForm()}
        {renderNOBO()}
        {renderDocuments()}
        {renderClientCertification()}
        <FormButtonFooter
          goForwardHandler={goForwardHandler}
          goBackHandler={goBackHandler}
          goForwardDisabledMessage={isNextButtonDisabled().disabledMessage}
          isGoForwardDisabled={isNextButtonDisabled().isDisabled}
          goForwardButtonText={t("forms.buttons.acceptAndContinue")}
        />
      </div>
    );
  },
);

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IDocumentsForm) => {
    OnboardingStore.updateFormData(changedFields, EOnboardingForms.DOCUMENTS);
  },
})(DocumentsForm);
